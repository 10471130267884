import React from 'react'
import Login from "./login";
import CreateAccount from "./createAccount";
import { MDBModal, MDBContainer, MDBBtn, MDBRow, MDBCol, MDBModalDialog, MDBModalContent, MDBModalTitle, 
    MDBModalBody, MDBModalHeader } from "mdb-react-ui-kit";

function RegisterOrLogin(props) {
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    return (
      <>
        <div className="ml-auto">
            <MDBBtn className="float-right" variant="primary" onClick={handleShow}>
                Sign In / Sign Up
            </MDBBtn>
        </div>

        <MDBModal
            show={show}
            tabIndex='-1'
        >
          <MDBModalDialog centered><MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle id="contained-modal-title-vcenter">
                    While You Were Pooping
                </MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={handleClose}></MDBBtn>
            </MDBModalHeader>

            <MDBModalBody>
                <MDBContainer>
                    <MDBRow>
                       <MDBCol lg={6}>
<Login userLoggedIn={props.userLoggedIn} setUserLoggedIn={props.setUserLoggedIn} />
                       </MDBCol>
                        <MDBCol lg={6}>
<CreateAccount userLoggedIn={props.userLoggedIn} setUserLoggedIn={props.setUserLoggedIn} />
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </MDBModalBody>
          </MDBModalContent></MDBModalDialog>
        </MDBModal>
      </>
    );
}

export default RegisterOrLogin