import React from 'react'
import { useState, useEffect } from 'react';
import { MDBBtn } from 'mdb-react-ui-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPoop } from '@fortawesome/free-solid-svg-icons';

function Like(props) {
    const [isLiked, setIsLiked] = useState(false)

    useEffect(() => {
        if (props.userLoggedIn.userId !== null) {
            const getParams = new URLSearchParams({
                poop_id : props.poop_id
            }).toString();

            fetch("/is_liked?" + getParams, {
                method: "GET",
                credentials: "include",
            }) 
            .then (response => response.json())
            .then(data => {
                if (data) {
                    setIsLiked(true)
                }
            });
        }
    }, [props.poop_id, props.userLoggedIn.userId]);


    const handleClick = (e) => {
        e.preventDefault();

        if (props.userLoggedIn.userId !== null) {
            fetch("/likes", {
                method: "POST",
                credentials: "include",
                body: JSON.stringify({
                    poop_id : props.poop_id
                }),
                headers: {
                    'Content-Type': 'application/json'
                },
            }) 
            .then (response => response.json())
            .then(data => {
                setIsLiked(data)
            });
        }
    };

    return (
        <>
            <div style={{float: 'right'}}>
                <MDBBtn color="link" className='button-right like-poop' onClick={e => { handleClick(e) }}>
                        <FontAwesomeIcon icon={faPoop} size="2x"
                            color={
                                isLiked ? "rgb(123, 63, 0)" : "#ccc"
                            }
                        />
                </MDBBtn>
            </div>
            <div style={{clear: 'both'}}></div>
        </>
    )
}

export default Like
