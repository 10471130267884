import React, { useState, useEffect } from 'react';
import PoopCardList from './poopCardList';

function AllPoops(props) {
    const [allPoops, setAllPoops] = useState([])

    useEffect(() => {
        fetch("/poops")
        .then(response => response.json())
        .then(data =>  {
            setAllPoops(data)
        })
    }, [props.isShart])

    return (
        <>
        { allPoops ?
            <PoopCardList
                listTitle="The Latest"
                poopList={allPoops}
                userLoggedIn={props.userLoggedIn}
            /> : null
        }
        </>
    )
}

export default AllPoops;
