import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

//import '@fortawesome/fontawesome-free/css/all.min.css';

// MDBReact v5
// - https://mdbootstrap.com/docs/b5/react/getting-started/installation/
//import 'mdb-react-ui-kit/dist/css/mdb.min.css';

// plain BootStrap
// import 'bootstrap/dist/css/bootstrap.min.css';

import './index.css';
import { MDBContainer, MDBRow } from "mdb-react-ui-kit";
import TopNavBar from './topNavBar';
//import UserAccountModal from './userAccountModal';
import OpenModal from './openModal';
import Logo from "./logo";
// import AddInlinePoop from "./addInlinePoop";
import AddPoop from "./addPoop";
import AllPoops from "./allPoops";
//import AllPoopsCarousel from "./allPoopsCarousel";


function App(){
  const [userLoggedIn, setUserLoggedIn] = useState({userId: null, username: null});
  const [onModal, setOnModal] = useState('');
  const [isShart, setIsShart] = useState(false);

  useEffect(() => {
    // if (localStorage.getItem("userId") !== null) {
    //   setUserLoggedIn({userId: localStorage.getItem("userId"), username: localStorage.getItem("username")})
    // }
    window.scrollTo(0, 0)
  }, [])

  return (
    <section className="page">
      <MDBContainer size='sm'>
        <MDBRow className="justify-content-center" style={{ backgroundColor: 'white'}}>

          <TopNavBar setOnModal={setOnModal} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn} />

          { onModal !== "" ?
            <OpenModal onModal={onModal} setOnModal={setOnModal} userLoggedIn={userLoggedIn} /> : null
          }

          <Logo />

          {/* <AddInlinePoop /> */}
          <AddPoop isShart={isShart} setIsShart={setIsShart} />

          <AllPoops isShart={isShart} userLoggedIn={userLoggedIn} />
          {/* <AllPoopsCarousel userLoggedIn={userLoggedIn} /> */}

        </MDBRow>
      </MDBContainer>
    </section>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

