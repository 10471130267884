import React, { useState, useEffect } from 'react';
import PoopCardList from './poopCardList';

function ViewLikedPoops(props) {
    const [userLikedPoops, setUserLikedPoops] = useState([])

    useEffect(() => {
        fetch("/user_likes")
        .then(response => response.json())
        .then(data =>  {
            setUserLikedPoops(data)
        })
    }, [])

    return (
        <>
        { userLikedPoops !== [] ?
            <PoopCardList
                listTitle="Poops to Remember"
                poopList={userLikedPoops}
                userLoggedIn={props.userLoggedIn}
            /> : null 
        }
        </>
    )
}

export default ViewLikedPoops