import React from 'react'
import { MDBNavbar, MDBContainer, MDBNavbarNav, MDBNavbarItem, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBDropdownDivider, MDBDropdownLink, MDBDropdown } from 'mdb-react-ui-kit';
import RegisterOrLogin from './registerOrLogin';

// https://react-bootstrap.netlify.app/components/navbar/
function TopNavBar(props) {

    const logOut = () => {
        fetch("/logout", {
            method: "POST",
            credentials: "include",
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then((response) => response.json())
        .then(data => {
            props.setUserLoggedIn({userId: null, username: null});
            localStorage.clear();
        })
    }

    const handleClick = (onModal, e) => {
        e.preventDefault();
        if (onModal === 'logout') {
            logOut();
        } else {
            props.setOnModal(onModal);
        }
    }

    return (
        <MDBNavbar expand="sm">
            <MDBContainer fluid>
                    <MDBNavbarNav right fullWidth={false}>
                        { props.userLoggedIn.userId ?
                            <MDBDropdown id="nav-acct-dropdown">
                                <MDBDropdownToggle>My Account</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                <MDBDropdownItem>
                                    <MDBDropdownLink type="button" onClick={e => { handleClick('accountinfo', e) }}>
                                    My Account Info
                                    </MDBDropdownLink>
                                </MDBDropdownItem>
                                <MDBDropdownItem>
                                    <MDBDropdownLink type="button" onClick={e => { handleClick('mypoops', e) }}>
                                        My Poops
                                    </MDBDropdownLink>
                                </MDBDropdownItem>
                                <MDBDropdownItem>
                                    <MDBDropdownLink type="button" onClick={e => { handleClick('likedpoops', e) }}>
                                        My Liked Poops
                                    </MDBDropdownLink>
                                </MDBDropdownItem>
                                
                                <MDBDropdownDivider />

                                <MDBDropdownItem>
                                    <MDBDropdownLink type="button" onClick={e => { handleClick('logout', e) }}>
                                        Logout
                                    </MDBDropdownLink>
                                </MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        :
                            <MDBNavbarItem>
                                <RegisterOrLogin userLoggedIn={props.userLoggedIn} setUserLoggedIn={props.setUserLoggedIn} />
                            </MDBNavbarItem>
                        }
                    </MDBNavbarNav>
            </MDBContainer>
        </MDBNavbar>
    )
}

export default TopNavBar
