import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBCard, MDBInput, MDBBtn } from "mdb-react-ui-kit";

function UpdateAccount() {
    const [userInfo, setUserInfo] = useState({});

    useEffect(() => {
        fetch("/users", {
            method: "GET"
        })
        .then((response) => response.json())
        .then((data) => {
            setUserInfo(data)
        })
    }, [])

    const updateAccount = (e) => {
        e.preventDefault();

        fetch("/update", {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({"newUsername": document.getElementById("update-username").value,
                                "newEmail": document.getElementById("update-email").value,
                                "oldPassword": document.getElementById("password").value,
                                "newPassword": document.getElementById("update-password").value}),
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(response => response.json())
        .then(result => {
            // alert(result.success)
        })
    }

    return(
        <MDBContainer>
            <MDBCard style={{padding: "2rem"}}>
                <h2>Update Your Account Info</h2>
                <p>Fill in any information you would like to alter, leave the rest blank</p>

                <form onSubmit={updateAccount}>
                    <label htmlFor="username">First Name </label>
                    <MDBInput type="text" placeholder={userInfo.username} id="update-username" />
                    <br />

                    <label htmlFor="your-email">Email </label>
                    <MDBInput type="text" placeholder={userInfo.email} id="update-email" />
                    <br />

                    <label htmlFor="your-password">Confirm Old Password </label>
                    <MDBInput type="password" placeholder="Up to 20 characters" id="password" />
                    <br />

                    <label htmlFor="your-password">New Password </label>
                    <MDBInput type="password" placeholder="Up to 20 characters" id="update-password" />
                    <br />
                    
                    <MDBBtn className="button" type="submit">Update</MDBBtn>
                </form>
            </MDBCard>
    </MDBContainer>
    );
}

export default UpdateAccount;