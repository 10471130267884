import React from "react";
import { Modal, Container } from 'react-bootstrap';
import ViewUserPoops from "./viewUserPoops";
import ViewLikedPoops from "./viewLikedPoops";
import UpdateAccount from "./updateAccount";

function OpenModal(props) {
    const handleClose = () => props.setOnModal("");

    // id="contained-modal-title-vcenter"
    return (
        <Modal
            show={true}
            onHide={handleClose}
            fullscreen={false}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    My Account
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Container>
                    { props.onModal === "accountinfo" ? <UpdateAccount userLoggedIn={props.userLoggedIn} /> : null }
                    { props.onModal === "mypoops" ? <ViewUserPoops userLoggedIn={props.userLoggedIn} /> : null }
                    { props.onModal === "likedpoops" ? <ViewLikedPoops userLoggedIn={props.userLoggedIn} /> : null }
                </Container>
            </Modal.Body>
        </Modal>
    );
}

export default OpenModal