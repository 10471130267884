import React, { useState } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBInput, MDBBtn } from "mdb-react-ui-kit";

function CreatePoop(props) {

    const [content, setContent] = useState('');

    function newPoop(e) {
        e.preventDefault();

        const newPoopDetails = {"content": content}

        fetch("/add_poop", {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(newPoopDetails),
            headers: {
                'Content-Type': 'application/json'
            },
        }) 
        .then (response => response.json())
        .then(data => {
            if (data.content === content) {
                // alert("Your poop has been created!");
            }
            props.setShow();
            // will toggle true/false but allPoops wiil refresh on any change
            props.setIsShart(!props.isShart);
        });
    };


    return(
                    <MDBCard>
                        <div style={{ margin: '2rem' }}>
                            <h3 className="text-center on-card">Add A Poop</h3>
                            
                            <form onSubmit={newPoop}>
                            {/* <label>
                                Post Anonymously?
                                <input
                                    name="anonymous"            type="checkbox"
                                    checked={this.state.isGoing}
                                    onChange={this.handleInputChange} />
                            </label>       */}
                                <MDBInput
                                    size="md"
                                    label="While you were pooping..."
                                    name="content"
                                    id="add-poop-text"
                                    onChange={(e) => setContent(e.target.value)}
                                    required
                                    textarea
                                    rows={2}
                                />
                                <br />
                                <div className="text-center">
                                    <MDBBtn className="button" type="submit">Share the load</MDBBtn>
                                </div>
                                <br />
                            </form>
                        </div>
                    </MDBCard>
  );
}

export default CreatePoop;