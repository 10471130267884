import React from 'react';
import CreatePoop from './createPoop';

import {MDBBtn, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter } from "mdb-react-ui-kit";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToilet, faPlus } from '@fortawesome/free-solid-svg-icons';


function AddPoop(props) {
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    return (
      <>
        <div className="text-center">
            <MDBBtn variant="info" onClick={handleShow} style={{ fontSize: '0.7rem' }}>
                <span style={{ fontSize: '1rem' }}>
                    Log a Log
                </span>
                &nbsp; &nbsp;
                <FontAwesomeIcon icon={faToilet} size="2x" />
                <FontAwesomeIcon icon={faPlus} size="1x" />
            </MDBBtn>
        </div>

        <MDBModal
            show={show}
            tabIndex='-1'
        >
            <MDBModalDialog centered size="lg"><MDBModalContent>
                <MDBModalHeader>
                    <MDBModalTitle id="contained-modal-title-vcenter">
                        Add A Poop
                    </MDBModalTitle>
                    <MDBBtn
                        className='btn-close'
                        color='none'
                        onClick={() => handleClose()}
                    ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                    <CreatePoop setShow={setShow} setIsShart={props.setIsShart} isShart={props.isShart} />
                </MDBModalBody>
            </MDBModalContent></MDBModalDialog>
        </MDBModal>
      </>
    );
}

export default AddPoop