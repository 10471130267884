import React from 'react';
import { 
    Container,
    Row,
    Col
} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToilet } from '@fortawesome/free-solid-svg-icons';
// import book-throne from './images/book-throne.jpg';
// import { faPoop } from '@fortawesome/free-solid-svg-icons';

function Logo() {
    
    return (
        <Container>
            <Row className="mt-4 pt-4 justify-content-center">
                <Col md="auto" style={{ textAlign: 'center' }} className="my-4">
                    <h2 style={{ color: '#E0A64C' }} id='logo-text' className="mb-4 pb-2">
                        <strong>While You Were Pooping</strong>
                    </h2>
                    <FontAwesomeIcon icon={faToilet} size="10x" color='#D9BB8B' />
                </Col>
            </Row>
        </Container>
    );
}

export default Logo
