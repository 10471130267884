import React from 'react';
// MDBReact v5
import { MDBCard, MDBCardTitle, MDBCardHeader, MDBCardBody, MDBCardText, MDBCardOverlay, MDBCardImage, MDBCardFooter } from 'mdb-react-ui-kit';
// MDBReact v4
// import { MDBBtn, MDBCard,  MDBCardBody, MDBCardImage, MDBCardTitle,  MDBCardText, MDBCol } from 'mdbreact';
// import { Card } from 'react-bootstrap';
import Like from './like';
import bg1 from './images/bg1.jpg';
import bg2 from './images/bg2.jpg';
import bg3 from './images/bg3.jpg';
import bg4 from './images/bg4.jpg';
import bg5 from './images/bg5.jpg';
import bg6 from './images/bg6.jpg';
import bg7 from './images/bg7.jpg';
import bg8 from './images/bg8.jpg';
import bg9 from './images/bg9.jpg';

function randomVal(vals) {
    return vals[(Math.floor(Math.random() * vals.length))];
}

function PoopCard(props) {
    const bgs = [bg1, bg2, bg3, bg4, bg5, bg6, bg7, bg8, bg9]
    const randomBg = randomVal(bgs);
    const tints = ['tint-green', 'tint-brown', 'tint-white'];
    const randomTint = randomVal(tints);


    return (
        <MDBCard>
            <MDBCardImage overlay src={randomBg} />
            <MDBCardOverlay className={randomTint}>

                {/* <MDBCardHeader> */}
                    <span className="poop-header">While you were pooping...</span>
                    <Like className='button-right' poop_id={props.poop.poop_id} userLoggedIn={props.userLoggedIn} />
                {/* </MDBCardHeader> */}

                <div className="poop-content align-items-center d-flex justify-content-center" style={{ height:'calc(100% - 6rem)' }}>
                    <MDBCardText className="poop-text">
                        {props.poop.content}
                    </MDBCardText>
                </div>
                
                <MDBCardFooter className="poop-footer d-flex justify-content-end">
                    &mdash; {props.poop.display_name}
                </MDBCardFooter>

            </MDBCardOverlay>
        </MDBCard>
    );
}

export default PoopCard