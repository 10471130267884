import React, { useState, useEffect } from 'react';
import PoopCardList from './poopCardList';

function ViewUserPoops(props) {
  const [userPoops, setUserPoops] = useState([])

  useEffect(() => {
    fetch("/user_poops")
    .then(response => response.json())
    .then(data =>  {
        setUserPoops(data)
    })
  }, [])

  return (
    <>
    { userPoops !== [] ?
        <PoopCardList
            listTitle="My Submitted Poops"
            poopList={userPoops}
            userLoggedIn={props.userLoggedIn}
        /> : null 
    }
    </>
  )
}

export default ViewUserPoops