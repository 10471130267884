import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import PoopCard from './poopCard';

function PoopCardList(props) {
    return (
        <MDBContainer className="mt-4 pt-4">
        <MDBRow className="justify-content-center">
            <MDBCol size="auto" className="pooplist-title text-center">
                {props.listTitle}
            </MDBCol>
        </MDBRow>

        {props.poopList.map((poop, idx) => (
            <MDBRow key={idx} className="justify-content-center pb-4">
                {/* <MDBCol style={{ maxWidth: '22rem' }}> */}
                <MDBCol sm='10' md='8' lg='6' xl='5'>
                    <PoopCard poop={poop} userLoggedIn={props.userLoggedIn} />
                </MDBCol>
            </MDBRow>
        ))}
        </MDBContainer>
    )

}

export default PoopCardList;