import React, {useState} from 'react';
import { 
    FormControl,
    Form,
    Card,
} from "react-bootstrap";

function Login(props) {
    const[email, setEmail] = useState('');
    const[password, setPassword] = useState('');

    const logUserIn = (e) => {
        e.preventDefault();

        const userDetails = {
            "email": email,
            "password": password
        };

        fetch(`/login`, {
            method: "post",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(userDetails)
        })
        .then((response) => response.json())
        .then(data => {
            if ('error' in data) {
                console.log('Login backend error: ' + data['error']);
            } else {
                // localStorage.setItem("userId", data["user_id"])
                // localStorage.setItem("username", data["username"])
                // localStorage.setItem("email", data["email"])

                props.setUserLoggedIn({userId: data["user_id"], username: data["username"]});
            }
        })
        .catch(error => console.log("Login Error: " + error));
    };

    return (
      <Card style={{padding: "2rem"}}>
        <h3>Sign In</h3>
        <Form action="/login" onSubmit={logUserIn}>
            <p>
                <FormControl type="text" id="login-email" name="email" placeholder="Your email" onChange={(e) => setEmail(e.target.value)} required />
            </p><p>
                <FormControl type="password" id="login-password" name="password" placeholder="Your password" onChange={(e) => setPassword(e.target.value)} required />
            </p><p>
                <button className="btn btn-primary" type="submit">Log In</button>
            </p>
        </Form>
      </Card>
    );
}

export default Login;
